<template>
  <div class="text">正在登录</div>
</template>

<script>
import { get } from 'lodash-es';
export default {
  created() {
    let openid = get(this.$route, 'query.openid');
    // 1-管理员 2-有信息 3-无信息
    let type = get(this.$route, 'query.type');

    if (!openid) {
      this.$router.replace('/nopermission');
    }

    window.sessionStorage.setItem('openid', openid);
    // 1-注册过的管理员 2-没注册过的管理员 3-没注册过的游客 4-注册过的游客
    if (type === '1' || type === '4') {
      this.$router.replace('/user/my');
    } else {
      this.$router.replace('/admin/info');
    }
  }
};
</script>

<style lang="less" scoped>
.text {
  margin-top: 20px;
  text-align: center;
  font-size: 30px;
}
</style>
